// Default

@import "default/variables";

.card-header>.nav .nav-link.active {
    color: #f7b924 !important;
}

.otpContainer {
    margin: 5% auto !important;
}

.otpInput {
    width: 3rem !important;
    height: 3rem !important;
    margin: 0 1rem !important;
    font-size: 2rem;
    text-align: center !important;
    border-radius: 4px !important;
    border: 1px solid rgba(0, 0, 0, 0.3) !important;
}

// Black

//@import "black/variables";

// Blue Alternate

//@import "blue-alt/variables";

// Dark

//@import "dark/variables";

// Green

//@import "green/variables";

// Orange

//@import "orange/variables";

// Purple

//@import "purple/variables";

// Purple Alternate

//@import "purple-alt/variables";

// Red

//@import "red/variables";